import React from "react";
import { useState } from "react";

export function InputPassword({ formChangeHandler, name, placeholder }) {
  const [isVisiblePassword, setIsVisiblePassword] = useState(false);
  const passwordType = !isVisiblePassword ? "password" : "text";

  return (
    <div className="auth-form__input-box">
      <label htmlFor="" className="auth-form__input-label">
        Пароль
      </label>
      <input
        onInput={formChangeHandler}
        name={name}
        type={passwordType}
        placeholder={placeholder}
        className="auth-form__input"
      />
      <button
        style={{ position: "absolute", top: "50%", right: 10 }}
        onClick={() => setIsVisiblePassword((prevState) => !prevState)}
      >
        {!isVisiblePassword ? (
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M21.25 9.14969C18.94 5.51969 15.56 3.42969 12 3.42969C10.22 3.42969 8.49 3.94969 6.91 4.91969C5.33 5.89969 3.91 7.32969 2.75 9.14969C1.75 10.7197 1.75 13.2697 2.75 14.8397C5.06 18.4797 8.44 20.5597 12 20.5597C13.78 20.5597 15.51 20.0397 17.09 19.0697C18.67 18.0897 20.09 16.6597 21.25 14.8397C22.25 13.2797 22.25 10.7197 21.25 9.14969ZM12 16.0397C9.76 16.0397 7.96 14.2297 7.96 11.9997C7.96 9.76969 9.76 7.95969 12 7.95969C14.24 7.95969 16.04 9.76969 16.04 11.9997C16.04 14.2297 14.24 16.0397 12 16.0397Z"
              fill="#989898"
            ></path>
            <path
              d="M12.0004 9.13965C10.4304 9.13965 9.15039 10.4196 9.15039 11.9996C9.15039 13.5696 10.4304 14.8496 12.0004 14.8496C13.5704 14.8496 14.8604 13.5696 14.8604 11.9996C14.8604 10.4296 13.5704 9.13965 12.0004 9.13965Z"
              fill="#989898"
            ></path>
          </svg>
        ) : (
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M23.2718 12.8433C21.5746 14.9434 17.691 18.8732 12.2115 18.8732C6.73195 18.8732 2.84849 14.9435 1.15069 12.8438C0.682426 12.2539 0.682426 11.4192 1.15124 10.8298C2.84843 8.72977 6.73203 4.80002 12.2115 4.80002C17.691 4.80002 21.5745 8.72973 23.2723 10.8294C23.7406 11.4193 23.7406 12.254 23.2718 12.8433ZM12.2115 8.0476C10.1175 8.0476 8.41683 9.74571 8.41683 11.8365C8.41683 13.9273 10.1175 15.6254 12.2115 15.6254C14.3054 15.6254 16.0061 13.9273 16.0061 11.8365C16.0061 9.74571 14.3054 8.0476 12.2115 8.0476ZM12.2115 9.13025C13.7076 9.13025 14.9218 10.3427 14.9218 11.8365C14.9218 13.3303 13.7076 14.5428 12.2115 14.5428C10.7154 14.5428 9.50112 13.3303 9.50112 11.8365C9.50112 10.3427 10.7154 9.13025 12.2115 9.13025Z"
              fill="#989898"
              fillRule="evenodd"
              clipRule="evenodd"
            ></path>
            <path
              d="M22.0799 5.99024C22.4262 6.53713 22.3391 7.21347 21.8853 7.50088L4.07593 18.7802C3.62211 19.0676 2.97344 18.8572 2.62708 18.3104C2.28071 17.7635 2.36782 17.0871 2.82164 16.7997L20.631 5.52042C21.0849 5.23301 21.7335 5.44335 22.0799 5.99024Z"
              fill="#989898"
              fillRule="evenodd"
              clipRule="evenodd"
              stroke="white"
              strokeLinecap="round"
            ></path>
          </svg>
        )}
      </button>
    </div>
  );
}
