import React, { useState, useContext, useEffect } from "react";
import Header from "../Header";
import Footer from "../Footer";
import { HeaderContext } from "../../context/HeaderContext";
import ButtonWithDangerous from "../common/ButtonWithDangerous";
import { AuthContext } from "../../context/AuthContext";
import { useNavigate } from "react-router-dom";

export default function BasketPage() {
  const [products, setProducts] = useState([]);
  const {
    get,
    removeById,
    storageLength,
    calculatedPrice,
    clearBasket,
    addOne,
    removeOne,
    calculatePrice,
  } = useContext(HeaderContext);
  const { isAuth } = useContext(AuthContext);
  const navigate = useNavigate();
  useEffect(() => {
    const prods = get();
    setProducts(prods);
    document.title = "Корзина";
  }, []);
  useEffect(() => {
    const prods = get();
    setProducts(prods);
  }, [storageLength]);

  const closeClickHandler = (event) => {
    setProducts((prev) => {
      return prev.filter(
        (prevProduct, index) => index !== event.target.dataset.productId
      );
    });
    removeById(event.target.dataset.productId);
  };
  const removeBasketHandler = async (e) => {
    e.preventDefault();
    clearBasket();
  };

  return (
    <>
      <Header />
      <main>
        <div className="basket-window__container container-1">
          <div className="basket-window__wrapper">
            <div className="basket__header">
              <button
                onClick={() => {
                  navigate(-1);
                }}
                className="basket__logo"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="20"
                  height="20"
                  viewBox="0 0 73 73"
                  fill="none"
                >
                  <path
                    d="M31.5833 58.9792C32.4167 58.1458 32.8167 57.1389 32.7833 55.9583C32.75 54.7778 32.3153 53.7708 31.4792 52.9375L19.7083 41.1667H66.1667C67.3472 41.1667 68.3375 40.7667 69.1375 39.9667C69.9375 39.1667 70.3361 38.1778 70.3333 37C70.3333 35.8194 69.9333 34.8292 69.1333 34.0292C68.3333 33.2292 67.3444 32.8305 66.1667 32.8333H19.7083L31.5833 20.9583C32.4167 20.125 32.8333 19.1347 32.8333 17.9875C32.8333 16.8403 32.4167 15.8514 31.5833 15.0208C30.75 14.1875 29.7597 13.7708 28.6125 13.7708C27.4653 13.7708 26.4764 14.1875 25.6458 15.0208L6.58333 34.0833C6.16666 34.5 5.87082 34.9514 5.69582 35.4375C5.52082 35.9236 5.43471 36.4444 5.43749 37C5.43749 37.5555 5.52499 38.0764 5.69999 38.5625C5.87499 39.0486 6.16944 39.5 6.58333 39.9167L25.75 59.0833C26.5139 59.8472 27.468 60.2292 28.6125 60.2292C29.7569 60.2292 30.7472 59.8125 31.5833 58.9792Z"
                    fill="#94A073"
                  />
                </svg>
              </button>
              <div className="basket__title">Корзина</div>
              {!!products && !!products.length && (
                <ButtonWithDangerous
                  ariaLabel="Удалить товары из корзины"
                  className="basket__delete"
                  onClick={removeBasketHandler}
                  title={"Очистка корзины"}
                  buttonIcon={
                    <span>
                      <svg
                        width="18"
                        height="18"
                        viewBox="0 0 18 18"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M7.5 13.5C7.69891 13.5 7.88968 13.421 8.03033 13.2803C8.17098 13.1397 8.25 12.9489 8.25 12.75V8.25C8.25 8.05109 8.17098 7.86032 8.03033 7.71967C7.88968 7.57902 7.69891 7.5 7.5 7.5C7.30109 7.5 7.11032 7.57902 6.96967 7.71967C6.82902 7.86032 6.75 8.05109 6.75 8.25V12.75C6.75 12.9489 6.82902 13.1397 6.96967 13.2803C7.11032 13.421 7.30109 13.5 7.5 13.5ZM15 4.5H12V3.75C12 3.15326 11.7629 2.58097 11.341 2.15901C10.919 1.73705 10.3467 1.5 9.75 1.5H8.25C7.65326 1.5 7.08097 1.73705 6.65901 2.15901C6.23705 2.58097 6 3.15326 6 3.75V4.5H3C2.80109 4.5 2.61032 4.57902 2.46967 4.71967C2.32902 4.86032 2.25 5.05109 2.25 5.25C2.25 5.44891 2.32902 5.63968 2.46967 5.78033C2.61032 5.92098 2.80109 6 3 6H3.75V14.25C3.75 14.8467 3.98705 15.419 4.40901 15.841C4.83097 16.2629 5.40326 16.5 6 16.5H12C12.5967 16.5 13.169 16.2629 13.591 15.841C14.0129 15.419 14.25 14.8467 14.25 14.25V6H15C15.1989 6 15.3897 5.92098 15.5303 5.78033C15.671 5.63968 15.75 5.44891 15.75 5.25C15.75 5.05109 15.671 4.86032 15.5303 4.71967C15.3897 4.57902 15.1989 4.5 15 4.5ZM7.5 3.75C7.5 3.55109 7.57902 3.36032 7.71967 3.21967C7.86032 3.07902 8.05109 3 8.25 3H9.75C9.94891 3 10.1397 3.07902 10.2803 3.21967C10.421 3.36032 10.5 3.55109 10.5 3.75V4.5H7.5V3.75ZM12.75 14.25C12.75 14.4489 12.671 14.6397 12.5303 14.7803C12.3897 14.921 12.1989 15 12 15H6C5.80109 15 5.61032 14.921 5.46967 14.7803C5.32902 14.6397 5.25 14.4489 5.25 14.25V6H12.75V14.25ZM10.5 13.5C10.6989 13.5 10.8897 13.421 11.0303 13.2803C11.171 13.1397 11.25 12.9489 11.25 12.75V8.25C11.25 8.05109 11.171 7.86032 11.0303 7.71967C10.8897 7.57902 10.6989 7.5 10.5 7.5C10.3011 7.5 10.1103 7.57902 9.96967 7.71967C9.82902 7.86032 9.75 8.05109 9.75 8.25V12.75C9.75 12.9489 9.82902 13.1397 9.96967 13.2803C10.1103 13.421 10.3011 13.5 10.5 13.5Z"
                          fill="#E22D38"
                        />
                      </svg>
                    </span>
                  }
                  description={"Вы точно хотите очистить корзину?"}
                />
              )}
            </div>
            <div className="basket__items">
              {products &&
                products.map((product, index) => {
                  return (
                    <React.Fragment key={index}>
                      <div className="basket__item basket-item">
                        <div className="basket-item__image">
                          <img
                            loading="lazy"
                            src={product.main_image}
                            alt={product.name}
                          />
                        </div>
                        <div className="basket-item__text">
                          <div className="basket-item__title">
                            <a href={"/product/" + product.link}>
                              {product.name}
                            </a>
                            <p>
                              {product.discounted_price
                                .toLocaleString("en")
                                .replace(",", " ")}{" "}
                              ₸ x{product.count}
                              {product.total_discount ? (
                                <div className="product-price__old product-price__old">
                                  {(product.price * product.count)
                                    .toLocaleString("en")
                                    .replace(",", " ")}{" "}
                                  ₸
                                </div>
                              ) : (
                                ""
                              )}
                            </p>
                            <div className="basket-item__counter">
                              <button
                                onClick={() => {
                                  removeOne(index);
                                  const prods = get();
                                  setProducts(prods);
                                  calculatePrice();
                                }}
                                className="basket-item__count-button"
                              >
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="24"
                                  height="24"
                                  viewBox="0 0 24 24"
                                  fill="none"
                                >
                                  <path
                                    d="M19 11H5C4.73478 11 4.48043 11.1054 4.29289 11.2929C4.10536 11.4804 4 11.7348 4 12C4 12.2652 4.10536 12.5196 4.29289 12.7071C4.48043 12.8946 4.73478 13 5 13H19C19.2652 13 19.5196 12.8946 19.7071 12.7071C19.8946 12.5196 20 12.2652 20 12C20 11.7348 19.8946 11.4804 19.7071 11.2929C19.5196 11.1054 19.2652 11 19 11Z"
                                    fill="#41492C"
                                  />
                                </svg>
                              </button>
                              <span className="basket-item__count-value">
                                {product.count}
                              </span>
                              <button
                                onClick={() => {
                                  addOne(index);
                                  const prods = get();
                                  setProducts(prods);
                                  calculatePrice();
                                }}
                                className="basket-item__count-button"
                              >
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="24"
                                  height="24"
                                  viewBox="0 0 24 24"
                                  fill="none"
                                >
                                  <path
                                    d="M19 11H13V5C13 4.73478 12.8946 4.48043 12.7071 4.29289C12.5196 4.10536 12.2652 4 12 4C11.7348 4 11.4804 4.10536 11.2929 4.29289C11.1054 4.48043 11 4.73478 11 5V11H5C4.73478 11 4.48043 11.1054 4.29289 11.2929C4.10536 11.4804 4 11.7348 4 12C4 12.2652 4.10536 12.5196 4.29289 12.7071C4.48043 12.8946 4.73478 13 5 13H11V19C11 19.2652 11.1054 19.5196 11.2929 19.7071C11.4804 19.8946 11.7348 20 12 20C12.2652 20 12.5196 19.8946 12.7071 19.7071C12.8946 19.5196 13 19.2652 13 19V13H19C19.2652 13 19.5196 12.8946 19.7071 12.7071C19.8946 12.5196 20 12.2652 20 12C20 11.7348 19.8946 11.4804 19.7071 11.2929C19.5196 11.1054 19.2652 11 19 11Z"
                                    fill="#41492C"
                                  />
                                </svg>
                              </button>
                            </div>
                          </div>
                          {!!product.error && (
                            <div className="basket-item__subtitle error">
                              <p>{product.error}</p>
                            </div>
                          )}
                        </div>
                        <button
                          data-product-id={index}
                          onClick={closeClickHandler}
                          className="basket-item__remove"
                        >
                          <svg
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M13.4099 11.9999L17.7099 7.70994C17.8982 7.52164 18.004 7.26624 18.004 6.99994C18.004 6.73364 17.8982 6.47825 17.7099 6.28994C17.5216 6.10164 17.2662 5.99585 16.9999 5.99585C16.7336 5.99585 16.4782 6.10164 16.2899 6.28994L11.9999 10.5899L7.70994 6.28994C7.52164 6.10164 7.26624 5.99585 6.99994 5.99585C6.73364 5.99585 6.47824 6.10164 6.28994 6.28994C6.10164 6.47825 5.99585 6.73364 5.99585 6.99994C5.99585 7.26624 6.10164 7.52164 6.28994 7.70994L10.5899 11.9999L6.28994 16.2899C6.19621 16.3829 6.12182 16.4935 6.07105 16.6154C6.02028 16.7372 5.99414 16.8679 5.99414 16.9999C5.99414 17.132 6.02028 17.2627 6.07105 17.3845C6.12182 17.5064 6.19621 17.617 6.28994 17.7099C6.3829 17.8037 6.4935 17.8781 6.61536 17.9288C6.73722 17.9796 6.86793 18.0057 6.99994 18.0057C7.13195 18.0057 7.26266 17.9796 7.38452 17.9288C7.50638 17.8781 7.61698 17.8037 7.70994 17.7099L11.9999 13.4099L16.2899 17.7099C16.3829 17.8037 16.4935 17.8781 16.6154 17.9288C16.7372 17.9796 16.8679 18.0057 16.9999 18.0057C17.132 18.0057 17.2627 17.9796 17.3845 17.9288C17.5064 17.8781 17.617 17.8037 17.7099 17.7099C17.8037 17.617 17.8781 17.5064 17.9288 17.3845C17.9796 17.2627 18.0057 17.132 18.0057 16.9999C18.0057 16.8679 17.9796 16.7372 17.9288 16.6154C17.8781 16.4935 17.8037 16.3829 17.7099 16.2899L13.4099 11.9999Z"
                              fill="#717C97"
                            />
                          </svg>
                        </button>
                      </div>
                    </React.Fragment>
                  );
                })}
              {!products.length && (
                <p className="basket__empty">Товаров в корзине пока нет</p>
              )}
            </div>
            {!!calculatedPrice.total_sum && (
              <div className="basket__sum">
                {!!calculatedPrice.order_discount &&
                  calculatedPrice.order_discount !== 0 && (
                    <p>
                      <span>Скидка</span> {calculatedPrice.order_discount}%
                    </p>
                  )}
                {!!calculatedPrice.delivery_price && (
                  <p className="basket__empty">
                    Стоимость доставки: {calculatedPrice.delivery_price}
                  </p>
                )}
                <span>Итого:</span>{" "}
                {!!calculatedPrice.order_discount ? (
                  <>
                    <span
                      className={"product-price__old"}
                      style={{ marginRight: 10 }}
                    >
                      {calculatedPrice.total_sum
                        ?.toLocaleString("en")
                        .replaceAll(",", " ")}
                    </span>
                    {calculatedPrice.total_paid
                      ?.toLocaleString("en")
                      .replaceAll(",", " ")}
                  </>
                ) : (
                  calculatedPrice.total_paid
                    ?.toLocaleString("en")
                    .replaceAll(",", " ")
                )}
                ₸
              </div>
            )}
            <p className="basket__min">*Минимальная сумма заказа 7000 тг</p>
            {!isAuth && (
              <p className="basket__min">
                **Выполните вход в аккаунт, если хотите отслеживать статус.
              </p>
            )}
            {calculatedPrice.can_create_order ? (
              <a href="/checkout" className="basket__checkout">
                Оформить заказ
              </a>
            ) : (
              ""
            )}
          </div>
        </div>
      </main>
      <Footer />
    </>
  );
}
