import React from "react";
import bg from "../img/PreLoader/bg.svg";
import placeholderImg from "../img/placeholder.jpeg";

const PreLoader = React.forwardRef((props, ref) => (
  <div ref={ref} className={"preloader"}>
    <div className="preloader__bg">
      <svg
        width="9923"
        height="6880"
        viewBox="0 0 9923 6880"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M2423.61 439.971C2870.64 482.019 3154 941.864 3161.22 1368.01C3170.07 1861.26 2892.94 2281.14 2597.77 2647.35C2276.68 3045.76 1964.78 3457.89 1878.53 3983.35C1817.2 4357.9 1914.28 4781.07 2206.5 5015.46C2414.1 5181.27 2696.16 5232.86 2956.24 5188.43C3415.39 5110 3776.16 4770.93 4084.13 4439.02C4343.55 4159.89 4592.81 3863.17 4910.61 3656.8C5228.41 3450.44 5636.74 3344.57 5987.34 3486.22C6413.7 3657.99 6645.9 4137.22 7045.7 4365.95C7268.72 4493.59 7530.76 4534.74 7786.58 4536.23C7965.65 4536.23 8148.99 4518.94 8311.33 4441.7C8662.59 4274.4 8840.02 3872.71 8941.69 3491.89C8986.62 3322.5 9023.68 3148.05 9110.27 2996.26C9196.85 2844.47 9344.44 2716.24 9516.62 2708.48"
          stroke="#94A073"
          stroke-miterlimit="10"
        />
        <path
          d="M2588.57 414.025C2870.63 448.32 3083.81 652.596 3197.62 907.569C3258.08 1042.63 3290.44 1186.72 3293.05 1332.52C3294.46 1463.23 3277.15 1593.55 3241.56 1720.2C3153.34 2040.48 2969.02 2334.52 2788.64 2590.99C2646.95 2791.09 2501.99 2986.42 2393.1 3199.64C2290.45 3394.11 2223.96 3602.7 2196.32 3816.94C2155.98 4155.12 2257.33 4532.65 2533.15 4743.19C2616.44 4805.89 2712.99 4852.38 2816.84 4879.77C2951.88 4914.69 3093.94 4920.91 3232.05 4897.97C3658.41 4828.18 3997.21 4525.8 4290.08 4228.18C4535.73 3978.27 4771.87 3710.78 5070.32 3524.69C5375.34 3334.73 5762.01 3235.13 6097.2 3356.2C6474.36 3492.19 6704.6 3865.25 7040.77 4088.32C7067.99 4106.21 7095.54 4123.21 7124.07 4139.01C7284.56 4224.67 7463.62 4277.58 7648.82 4294.08C7709.17 4300.35 7769.52 4303.63 7829.54 4304.82C7999.75 4307.5 8174.23 4292 8329.69 4222.51C8665.86 4073.4 8847.56 3707.8 8960.05 3357.69C9009.9 3202.32 9052.54 3041.29 9138.14 2899.93C9223.74 2758.58 9363.78 2637.51 9524.49 2622.89"
          stroke="#94A073"
          stroke-miterlimit="10"
        />
        <path
          d="M2753.89 388.08C3025.12 429.234 3228.46 633.51 3336.04 881.922C3392.62 1014.26 3422.62 1154.76 3424.59 1296.74C3426.03 1424.53 3409.72 1551.98 3376.05 1676.06C3293.4 1988 3130.07 2283.82 2979.2 2534.62C2865.73 2723.69 2742.74 2901.13 2657.14 3095.26C2576.55 3272.15 2528.25 3459.8 2514.14 3650.84C2494.46 3952.33 2600.4 4286.33 2858.84 4470.92C2936.76 4526.07 3025.94 4566.62 3121.22 4590.21C3246.24 4621.27 3377.26 4626.87 3504.94 4606.61C3898.5 4546.97 4216.64 4278.58 4493.44 4016.45C4725.65 3795.47 4948.66 3557.2 5227.44 3391.99C5519.33 3218.72 5883.38 3123.59 6204.13 3225.58C6564.9 3338.31 6796.12 3662.17 7120.15 3864.95C7146.06 3881.35 7172.63 3896.86 7199.85 3911.18C7353.23 3990.76 7522.66 4041.47 7698.04 4060.28C7754.84 4067.04 7811.98 4071.12 7869.24 4072.51C8030.93 4077.58 8197.21 4064.46 8344.79 4003.03C8666.21 3869.72 8852.16 3542.29 8975.81 3222.9C9030.25 3081.25 9078.14 2933.93 9163.08 2803.01C9248.02 2672.1 9380.2 2558.18 9529.42 2536.71"
          stroke="#94A073"
          stroke-miterlimit="10"
        />
        <path
          d="M2918.84 361.838C3181.22 410.447 3373.41 613.232 3474.1 856.574C3527.23 986.082 3555.09 1123.03 3556.42 1261.25C3557.13 1385.61 3541.83 1509.61 3510.83 1630.74C3433.75 1935.51 3290.43 2231.64 3170.07 2478.56C3083.81 2655.1 2983.12 2816.14 2921.14 2990.59C2862.68 3149.85 2832.46 3316.54 2831.6 3484.43C2831.6 3749.55 2943.44 4038.81 3184.5 4200.15C3258.01 4247.81 3340.65 4282.6 3428.18 4302.73C3543.36 4329.52 3663.39 4334.39 3780.75 4317.05C4141.51 4264.26 4438.65 4034.94 4699.06 3806.5C4917.82 3614.75 5127.72 3406 5387.8 3261.07C5667.23 3104.51 6010.94 3016.24 6314.64 3096.46C6658.36 3185.92 6891.21 3460.58 7202.79 3643.38C7227.71 3657.99 7252.97 3673.2 7279.2 3685.13C7425.5 3758.47 7585.35 3806.78 7750.82 3827.68C7804.61 3834.83 7858.73 3839.31 7912.84 3841.69C8066.08 3853.2 8220.21 3833.65 8364.13 3784.44C8670.78 3668.13 8861 3377.97 8995.47 3089.6C9054.83 2961.66 9107.64 2827.77 9192.25 2707.29C9276.87 2586.81 9400.51 2480.05 9538.92 2452.02"
          stroke="#94A073"
          stroke-miterlimit="10"
        />
        <path
          d="M3083.82 335.895C3333.74 391.362 3518.38 593.253 3612.18 830.929C3661.48 957.684 3687.2 1091.02 3688.27 1225.47C3688.65 1346.52 3674.24 1467.24 3645.31 1585.41C3574.14 1883.62 3450.49 2177.66 3360.96 2422.2C3301.27 2585.02 3223.87 2731.15 3185.17 2886.22C3148.69 3027.7 3136.63 3173.48 3149.42 3318.33C3171.39 3546.76 3286.51 3791.59 3510.19 3926.39C3578.66 3967.33 3654.46 3997.1 3734.19 4014.36C3839.51 4036.89 3948.58 4041.04 4055.6 4026.59C4385.54 3982.45 4659.72 3788.02 4905.04 3595.67C5110.35 3432.84 5307.46 3253.91 5546.22 3128.96C5813.19 2989.4 6134.93 2906.79 6423.22 2966.74C6751.19 3033.24 6984.04 3258.39 7283.15 3420.62C7307.09 3433.74 7331.36 3446.26 7356.29 3458.19C7495.43 3526.03 7645.9 3572.58 7801.67 3595.97C7852.18 3603.42 7903.34 3608.49 7954.18 3611.47C8098.23 3623.9 8243.53 3608.45 8380.54 3566.14C8672.43 3466.84 8866.92 3213.95 9012.21 2956.3C9077.81 2842.08 9134.54 2721.9 9218.18 2611.86C9301.81 2501.82 9417.91 2403.11 9546.15 2367.33"
          stroke="#94A073"
          stroke-miterlimit="10"
        />
        <path
          d="M3248.78 309.649C3488.2 372.274 3663.33 572.972 3750.57 805.579C3796.16 929.508 3819.52 1059.3 3819.77 1189.98C3819.9 1307.51 3806.6 1424.74 3780.09 1539.78C3714.49 1830.54 3609.22 2121.6 3550.51 2365.83C3515.75 2514.94 3463.27 2645.56 3447.86 2783.33C3433.32 2906.81 3439.41 3031.59 3465.89 3153.41C3508.53 3345.46 3629.88 3545.57 3834.53 3655.61C3898.07 3689.1 3967.03 3713.26 4038.86 3727.18C4134.13 3745.69 4232.2 3749.22 4328.78 3737.62C4626.25 3702.13 4879.12 3545.57 5108.7 3386.32C5300.23 3252.72 5485.2 3103.61 5703.31 2998.64C5957.81 2876.08 6257.9 2798.84 6530.45 2838.2C6840.05 2882.04 7075.86 3057.69 7362.83 3199.64C7385.46 3210.97 7408.75 3222.3 7432.36 3233.04C7564.56 3294.05 7705.55 3337.82 7851.18 3363.06C7898.49 3371.02 7946.22 3376.79 7994.18 3380.36C8129.49 3394.05 8266.44 3382.9 8396.92 3347.55C8674.06 3265.54 8872.81 3049.34 9028.92 2822.99C9097.79 2722.5 9160.11 2614.24 9244.4 2515.83C9328.69 2417.42 9435.28 2323.78 9551.05 2282.63"
          stroke="#94A073"
          stroke-miterlimit="10"
        />
        <path
          d="M3413.76 283.708C3643.34 353.192 3807.32 552.1 3888.66 779.936C3930.99 900.968 3952.67 1027.19 3952.94 1154.19C3952.58 1268.43 3940.17 1382.37 3915.88 1494.46C3855.86 1778.06 3766.33 2064.04 3744.02 2309.47C3731.89 2441.58 3705.98 2560.57 3714.83 2677.17C3721.8 2782.57 3745.78 2886.47 3786 2985.52C3852.01 3156.19 3986.66 3297.8 4162.84 3381.85C4221.17 3407.99 4283.2 3426.66 4347.16 3437.32C4432.49 3451.71 4519.69 3454.52 4605.93 3445.67C4871.26 3418.83 5102.48 3298.35 5316.31 3173.99C5494.4 3069.32 5666.91 2950.63 5864.68 2865.34C6107.05 2760.37 6385.17 2686.42 6641.97 2706.99C6934.52 2727.57 7171.64 2853.71 7446.15 2975.38L7511.74 3005.21C7637.21 3060.19 7769.29 3101.72 7905.31 3128.96C7949.58 3137.31 7994.19 3143.58 8038.79 3148.05C8165.24 3162.74 8293.59 3155.86 8417.27 3127.77C8679.64 3062.46 8882.33 2883.83 9049.59 2687.91C9123.06 2601.13 9191.93 2508.98 9274.25 2419.51C9352.33 2328.39 9449.12 2251.93 9559.26 2194.36"
          stroke="#94A073"
          stroke-miterlimit="10"
        />
        <path
          d="M3578.73 257.465C3797.16 334.106 3953.27 532.418 4027.07 754.587C4065.42 872.799 4084.65 995.458 4084.13 1118.71C4084.13 1229.52 4073.15 1340.1 4051.34 1449.13C3997.22 1725.57 3917.85 2006.49 3935.56 2254.3C3944.09 2370.01 3947.7 2476.17 3979.84 2573.69C4007.58 2660.89 4048.88 2744.04 4102.5 2820.61C4193.79 2951.87 4328.47 3053.45 4487.21 3110.77C4540.17 3129.17 4595.14 3142.37 4651.19 3150.14C4726.55 3160.13 4802.92 3162.23 4878.8 3156.4C5111.33 3138.21 5321.56 3054.11 5519.66 2964.05C5683.64 2888.9 5844.35 2800.93 6021.12 2734.43C6250.7 2647.95 6507.5 2579.65 6748.56 2578.16C7024.38 2575.78 7262.82 2652.72 7524.87 2754.11L7587.84 2778.86C7705.81 2828.2 7828.27 2868.11 7953.85 2898.15C7995.18 2906.79 8036.5 2913.65 8078.15 2918.72C8195.75 2934.42 8315.44 2931.7 8432.03 2910.67C8679.32 2862.36 8886.93 2721.01 9065.01 2555.8C9143.07 2483.03 9217.19 2403.41 9298.86 2324.38C9378.45 2244.69 9467.93 2173.64 9565.5 2112.65"
          stroke="#94A073"
          stroke-miterlimit="10"
        />
        <path
          d="M3743.68 231.52C3951.29 315.019 4098.22 512.139 4165.13 728.94C4199.71 844.267 4216.6 963.387 4215.3 1082.92C4213.63 1190.49 4202.67 1297.78 4182.51 1403.8C4133.97 1672.19 4058.86 1951.62 4122.82 2197.05C4145.07 2292.72 4184.75 2384.34 4240.23 2468.12C4289.55 2537.28 4349.65 2599.56 4418.65 2653.01C4570.2 2770.84 4757.78 2843.69 4956.19 2861.76C5021.56 2867.89 5087.35 2869.39 5152.97 2866.24C5348.1 2851.79 5540.18 2813.37 5723.97 2752.02C5874.83 2705.8 6023.08 2649.73 6179.19 2602.91C6398.27 2535.22 6631.13 2470.51 6856.77 2448.74C7109.97 2423.03 7366.28 2451.52 7605.2 2531.94C7624.88 2538.5 7644.56 2545.06 7664.57 2552.22C7775.43 2594.9 7888.75 2632.14 8004.01 2663.75C8042.28 2673.09 8080.65 2680.55 8119.13 2686.12C8228.1 2703 8339.3 2704.41 8448.74 2690.29C8681.27 2660.47 8892.81 2554.9 9081.72 2421.9C9164.7 2362.26 9244.07 2296.65 9325.08 2228.06C9406.09 2159.47 9489.06 2088.79 9572.04 2027.36"
          stroke="#94A073"
          stroke-miterlimit="10"
        />
        <path
          d="M3908.66 205.572C4105.44 295.036 4243.19 492.156 4302.22 703.589C4361.25 915.023 4352.73 1141.37 4317.31 1359.66C4274.67 1621.79 4197.93 1903.9 4314.03 2141.87C4357.72 2228.62 4422.95 2304.98 4504.58 2364.94C4718.74 2525.08 4996.53 2564.14 5262.52 2573.69C5624.49 2585.59 5986.5 2551.16 6337.93 2471.4C6784.63 2370.31 7242.8 2195.85 7686.21 2309.77C7811.5 2341.98 7930.55 2396.55 8055.51 2430.84C8592.72 2579.95 9174.87 2330.64 9579.26 1940.88"
          stroke="#94A073"
          stroke-miterlimit="10"
        />
        <path
          d="M4109.72 1C4306.5 90.4641 4444.25 287.583 4503.28 499.017C4562.32 710.45 4553.79 936.795 4518.37 1155.09C4475.41 1417.22 4398.66 1699.33 4514.76 1937.3C4559.21 2023.79 4625.11 2099.74 4707.28 2159.17C4921.44 2319.31 5199.24 2358.38 5464.89 2367.92C5826.97 2379.81 6189.09 2345.38 6540.63 2265.63C6986.34 2164.54 7444.52 1989.49 7888.59 2104.9C8014.2 2137.1 8133.26 2191.68 8257.88 2225.97C8795.43 2375.08 9377.25 2125.77 9781.63 1736.01"
          stroke="#94A073"
          stroke-miterlimit="10"
        />
        <path
          d="M4664.97 1C4832.23 93.4462 4932.26 275.655 4967.36 469.792C5002.45 663.929 4981.46 864.03 4934.56 1058.47C4878.15 1301.21 4791.56 1560.36 4918.16 1770.9C4971.71 1855.63 5046.28 1927.83 5135.93 1981.74C5370.1 2128.16 5660.69 2123.09 5936.84 2132.63C6257.92 2143.67 6580.64 2095.35 6894.84 2019.91C7217.89 1942.37 7547.82 1825.47 7873.17 1831.73C7949.23 1833.04 8024.9 1841.94 8098.81 1858.27C8211.31 1883.62 8318.23 1928.06 8430.39 1955.79C8912.18 2075.08 9432.01 1870.8 9803.27 1550.22"
          stroke="#94A073"
          stroke-miterlimit="10"
        />
        <path
          d="M5220.55 1C5357.64 95.8319 5420.94 263.428 5430.78 440.269C5436.08 617.1 5409.83 793.544 5353.05 962.739C5282.21 1186.4 5189.07 1421.69 5323.86 1605.69C5387.27 1688.25 5471.06 1756.17 5568.53 1804C5823.03 1937 6126.08 1923.29 6413.05 1897.94C6693.79 1873.19 6976.83 1845.75 7252.98 1774.77C7537 1701.71 7826.6 1598.23 8113.9 1595.85C8180.73 1595.14 8247.46 1600.83 8312.98 1612.84C8411.37 1631.33 8507.47 1665.33 8608.15 1686.5C9034.51 1778.06 9491.7 1616.72 9829.84 1365.33"
          stroke="#94A073"
          stroke-miterlimit="10"
        />
        <path
          d="M5776.11 1C5883.36 97.9194 5910.25 250.307 5893.85 411.044C5875.29 567.51 5833.58 720.983 5769.88 867.311C5683.95 1071.89 5588.51 1281.53 5727.57 1440.48C5801.29 1520.96 5894.29 1585.02 5999.46 1627.76C6274.3 1747.04 6589.81 1709.76 6887.28 1664.14C7127.68 1627.76 7371.69 1597.64 7608.81 1530.84C7853.8 1462.25 8103.06 1372.19 8351.99 1362.05C8409.74 1359.71 8467.61 1362.31 8524.83 1369.8C8611.09 1381.43 8694.39 1404.99 8780.65 1419.9C9151.91 1482.82 9546.78 1364.73 9851.47 1181.33"
          stroke="#94A073"
          stroke-miterlimit="10"
        />
        <path
          d="M6331.7 1C6408.77 100.305 6400.57 237.483 6357.28 381.819C6314.44 516.507 6257.44 647.131 6187.06 771.882C6084.08 957.073 5990.28 1139.88 6131.63 1275.27C6216.15 1352.2 6318.12 1411.44 6430.42 1448.83C6725.59 1554.39 7053.56 1497.44 7361.85 1427.95C7561.91 1383.22 7766.24 1346.54 7965.97 1284.21C8171.61 1220.4 8381.18 1143.46 8591.74 1125.27C8640.36 1120.95 8689.29 1120.35 8738.01 1123.48C8811.48 1127.95 8882.65 1141.67 8956.11 1149.72C9271.95 1184.31 9604.18 1109.76 9874.43 997.034"
          stroke="#94A073"
          stroke-miterlimit="10"
        />
        <path
          d="M6887.3 1C6934.53 102.393 6892.22 224.958 6821.7 352.296C6756.13 463.775 6683.87 571.891 6605.24 676.156C6484.55 841.068 6392.39 996.437 6536.7 1110.06C6632.16 1183.51 6743.09 1238.5 6862.7 1271.69C7178.21 1363.54 7518.64 1292.86 7837.43 1193.56C7998.13 1143.75 8162.45 1097.23 8323.81 1039.68C8490.42 980.035 8659.65 916.516 8832.16 890.571C8871.74 884.34 8911.73 880.455 8951.87 878.941C9012.22 876.853 9071.58 878.941 9132.25 881.625C9390.36 884.518 9648.04 861.718 9900.69 813.632"
          stroke="#94A073"
          stroke-miterlimit="10"
        />
        <path
          d="M7442.87 1C7497.65 333.508 6603.93 729.536 6939.44 944.846C7363.83 1217.41 7869.56 1148.23 8310.68 958.862C8560.92 852.4 8806.9 722.677 9069.28 654.684C9349.04 582.815 9638.31 592.954 9922 629.038"
          stroke="#94A073"
          stroke-miterlimit="10"
        />
        <path
          d="M2815.88 5636.34C3446.89 5483.36 3734.19 5226 4068.39 4826.69C4396.36 4437.53 4617.09 3954.42 5101.5 3736.13C5342.56 3627.58 5680.04 3563.16 5932.25 3676.48C6380.91 3875.99 6578.68 4396.67 7041.44 4589.02C7474.69 4769.44 7959.76 4717.25 8409.4 4635.24C8743.93 4574.11 9074.2 4489.12 9410.04 4435.14"
          stroke="#94A073"
          stroke-miterlimit="10"
        />
        <path
          d="M9302.45 4631.36C8977.44 4685.64 8655.7 4753.93 8331.66 4797.47C7908.25 4854.72 7456.64 4878.58 7045.04 4724.7C6610.48 4560.39 6398.61 4130.36 5978.48 3996.47C5732.83 3918.04 5418.31 4006.31 5183.81 4121.42C4748.26 4334.94 4526.23 4759.89 4220.23 5112.68C3914.23 5465.47 3643.66 5697.48 3088.73 5843.6"
          stroke="#94A073"
          stroke-miterlimit="10"
        />
        <path
          d="M9194.87 4828.78C8883.97 4887.31 8570.04 4931.6 8254.25 4961.49C7856.1 4993.99 7437.93 4991.31 7048.63 4861.58C6642.28 4726.79 6412.7 4388.92 6025.04 4317.64C5783.98 4273.51 5494.06 4384.14 5266.44 4506.41C4878.78 4715.16 4657.4 5082.56 4372.4 5398.37C4094.61 5706.12 3840.1 5911.89 3361.27 6050.86"
          stroke="#94A073"
          stroke-miterlimit="10"
        />
        <path
          d="M9086.98 5027.39C8787.21 5084.26 8482.59 5117.39 8176.53 5126.4C7796.69 5138.95 7417.11 5095.44 7052.58 4997.57C6675.08 4892.3 6426.81 4650.75 6071.29 4639.71C5833.84 4631.96 5569.82 4762.28 5349.76 4892.3C5009.65 5092.1 4789.91 5406.42 4525.57 5684.95C4276.31 5947.38 4037.88 6126.91 3635.13 6259.31"
          stroke="#94A073"
          stroke-miterlimit="10"
        />
        <path
          d="M8979.41 5223.92C8690.08 5279.36 8394.2 5301.3 8098.81 5289.22C7752.15 5274.91 7401.55 5211.99 7056.2 5135.35C6705.27 5057.51 6438.96 4917.65 6117.87 4960.29C5883.37 4990.12 5644.94 5137.73 5431.43 5276.4C5136.26 5466.66 4920.78 5728.49 4677.1 5970.05C4465.5 6186.53 4202.19 6356.07 3907.36 6465.68"
          stroke="#94A073"
          stroke-miterlimit="10"
        />
        <path
          d="M8872.49 5421.33C8592.34 5476.21 8303.9 5486.9 8019.76 5452.94C7699.01 5416.56 7382.19 5322.62 7058.48 5272.22C6734.78 5221.83 6451.41 5188.72 6162.8 5281.47C5933.22 5355.43 5717.74 5512.88 5512.43 5661.39C5264.81 5840.32 5051.63 6052.05 4827.3 6255.73C4636.39 6425.01 4417.35 6565.9 4178.25 6673.23"
          stroke="#94A073"
          stroke-miterlimit="10"
        />
        <path
          d="M8764.26 5619.05C8494.09 5673.15 8214.11 5672.33 7944.33 5616.66C7649.16 5559.11 7366.45 5432.37 7064.72 5407.91C6769.54 5385.25 6471.42 5461.59 6211.99 5601.45C5989.3 5720.74 5793.5 5884.76 5598.03 6045.2C5394.36 6212.2 5187.74 6375.32 4982.11 6540.23C4822.06 6669.06 4628.23 6767.47 4454.07 6879"
          stroke="#94A073"
          stroke-miterlimit="10"
        />
        <path
          d="M2178.28 549.713C2625.96 572.974 2998.21 953.494 2987.39 1389.78C2969.02 2285.61 2129.42 2793.47 1728.97 3506.5C1368.2 4146.47 1484.3 5012.18 2188.12 5350.36C2360.31 5432.96 2610.55 5492.9 2676.47 5700.76C2748.95 5927.7 2553.15 6163.88 2414.1 6318.95"
          stroke="#94A073"
          stroke-miterlimit="10"
        />
        <path
          d="M1823.11 596.831C2229.46 617.408 2550.22 945.145 2590.56 1327.75C2620.92 1605.32 2554.37 1884.54 2400.34 2125.77C2231.1 2396.25 1995.95 2628.26 1812.94 2896.36C1676.65 3099.05 1566.59 3315.4 1484.97 3541.09C1296.72 4044.78 1334.76 4652.54 1684.71 5067.35C1763.05 5159.25 1856.4 5239.7 1961.51 5305.92C2106.47 5400.16 2313.1 5474.71 2366.88 5673.62C2424.61 5890.42 2257.34 6114.98 2137.3 6270.05"
          stroke="#94A073"
          stroke-miterlimit="10"
        />
        <path
          d="M1467.9 643.652C1832.6 661.545 2113.67 939.778 2193.7 1265.43C2265.91 1544.79 2214.55 1838.76 2050.7 2084.02C1869.34 2362.56 1612.53 2590.99 1451.5 2887.41C1324.58 3120.91 1279.97 3352.03 1238.65 3576.88C1151.08 4051.64 1208.8 4600.35 1504.63 5016.96C1569.95 5107.99 1647.03 5191.59 1734.21 5265.96C1853.26 5368.55 2017.58 5459.8 2057.59 5647.98C2101.21 5854.64 1962.15 6066.97 1858.84 6224.12"
          stroke="#94A073"
          stroke-miterlimit="10"
        />
        <path
          d="M1112.4 691.065C1436.11 706.274 1687.99 935.601 1797.2 1203.69C1855.41 1340.28 1877.29 1487.44 1861.05 1633.17C1844.8 1778.91 1790.9 1919.09 1703.73 2042.27C1510.23 2328.85 1230.8 2552.22 1092.72 2880.25C984.491 3134.03 994.331 3378.57 994.331 3614.45C998.922 4052.53 1086.16 4548.16 1329.19 4967.75C1381.56 5057.95 1441.77 5144.21 1509.24 5225.7C1602.39 5339.32 1723.74 5445.49 1750.63 5623.52C1779.82 5820.04 1668.96 6020.74 1583.04 6179.09"
          stroke="#94A073"
          stroke-miterlimit="10"
        />
        <path
          d="M757.193 738.184C1039.25 750.709 1271.45 927.847 1400.34 1141.67C1572.2 1426.46 1547.27 1727.06 1355.74 2000.52C1150.1 2295.16 849.024 2515.24 732.595 2870.11C643.059 3143.87 699.798 3402.13 750.633 3648.75C837.59 4082.01 971.282 4506.43 1149.77 4915.86C1191.1 5009.2 1236.36 5097.77 1280.96 5184.25C1346.55 5309.2 1425.92 5429.68 1439.7 5597.57C1454.46 5783.96 1372.14 5972.73 1303.92 6132.27"
          stroke="#94A073"
          stroke-miterlimit="10"
        />
        <path
          d="M401.999 785.301C519.518 792.624 633.965 822.668 737.693 873.427C841.42 924.185 932.04 994.488 1003.5 1079.64C1240.62 1352.8 1212.08 1676.06 1007.43 1958.77C789.659 2261.46 465.297 2477.07 372.481 2861.17C300.656 3155.2 400.031 3430.46 505.965 3684.53C662.079 4058.79 838.199 4443.79 971.355 4865.46C1001.2 4959.1 1029.73 5050.95 1054.99 5141.01C1093.36 5277.59 1131.08 5412.09 1131.73 5570.44C1131.73 5746.38 1077.94 5923.52 1027.11 6083.96"
          stroke="#94A073"
          stroke-miterlimit="10"
        />
        <path
          d="M46.8149 832.717C246.221 839.576 448.906 892.36 606.66 1017.61C917.575 1262.44 877.235 1622.68 659.135 1917.32C429.228 2227.76 82.8916 2439.19 12.706 2852.52C-40.7531 3166.84 96.9943 3464.15 261.307 3720.32C487.607 4072.81 714.234 4391.6 793.275 4814.76C868.708 5218.55 831.975 5637.83 749.655 6037.44"
          stroke="#94A073"
          stroke-miterlimit="10"
        />
      </svg>
    </div>
    <div className="preloader__logo">
      <img src={require("../img/PreLoader/logo.png")} alt="" />
    </div>
  </div>
));

export default PreLoader;
