import React, { useState, useEffect, useRef } from "react";
import { Autoplay, Navigation, Swiper, Grid } from "swiper";
import Rating from "react-rating";
import placeholderImg from "../../img/placeholder.jpeg";
import ProductCard from "../common/ProductCard";

export default function NewProducts({ products }) {
  const slider = useRef();
  useEffect(() => {
    if (slider.current.className)
      new Swiper("." + slider.current.classList.value.split(" ").join("."), {
        modules: [Navigation, Autoplay, Grid],
        observer: true,
        observeParents: true,
        slidesPerView: 4,
        spaceBetween: 30,
        autoHeight: false,
        speed: 800,
        // Эффекты
        effect: "fade",
        autoplay: {
          delay: 3000,
          disableOnInteraction: false,
        },
        navigation: {
          nextEl: "." + slider.current.classList[0] + " .swiper-next-btn",
          prevEl: "." + slider.current.classList[0] + " .swiper-prev-btn",
        },
        breakpoints: {
          320: {
            slidesPerView: 2,
            slidesPerColumn: 2,
            spaceBetween: 10,
            grid: {
              fill: "row",
              rows: 2,
            },
          },
          920: {
            slidesPerView: 2,
          },
          1440: {
            slidesPerView: 4,
          },
          1920: {
            slidesPerView: 4,
          },
        },
        // События
        on: {},
      });
  }, [slider]);
  return (
    <>
      <section className="page__popular popular">
        <div className="popular__header">
          <h2 className="popular__title section-title">Новинки и актуальное</h2>
        </div>
        <div className="popular__container">
          <div
            ref={slider}
            className="popular-products popular__content products sponsors-swiper"
          >
            <div className="popular-products__swipe-anim">
              <img src={require("../../img/swipe.gif")} alt="" />
            </div>
            <div className="popular__swiper-wrapper swiper-wrapper">
              {products?.map((product) => {
                return <ProductCard key={product.id} product={product} />;
              })}
            </div>
            <div className="brands__buttons">
              <button className="swiper-prev-btn brands-prev-btn _icon-arrow-slider"></button>
              <button className="swiper-next-btn brands-next-btn _icon-arrow-slider"></button>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
