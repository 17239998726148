import React from "react";
import SuccessImage from "../img/success.png";
function SuccessPopup(props) {
  return (
    <div className={"modal-purchase"}>
      <div className="modal-purchase__header">
        <div className="modal-purchase__icon">
          <img src={SuccessImage} alt="" />
        </div>
      </div>
      <div className="modal-purchase__text">
        <h2 className="modal-purchase__title">Все прошло хорошо</h2>
        <div className="modal-purchase__subtitle">
          <p>Спасибо! Мы получили Ваш заказ. Мы его уже собираем.</p>
        </div>
      </div>
    </div>
  );
}

export default SuccessPopup;
