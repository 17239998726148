import React, { useContext } from "react";
import { HeaderContext } from "../context/HeaderContext";

export default function SearchResults({ results }) {
  const { add, isAlreadyInBasket } = useContext(HeaderContext);

  function addClickHandler(event, product) {
    if (isAlreadyInBasket(product.id)) {
      window.location.href = "/basket";
      return;
    }
    const status = add(product);
    if (!status) {
      const error = event.target.parentElement.querySelector(".error");
      if (!error)
        event.target.insertAdjacentHTML(
          "beforebegin",
          `<p class="error">Товар заканчивается, Вы можете заказать не больше ${product.amount} штук</p>`
        );
    } else {
      animationAddToBasket(event.target.closest(".basket-item"));
    }
  }

  function animationAddToBasket(image) {
    const cloneProduct = image.cloneNode(true);
    cloneProduct.style.position = "fixed";
    cloneProduct.style.pointerEvents = "none";
    const productPosition = getPosition(image);
    cloneProduct.style.top = `${productPosition.top}px`;
    cloneProduct.style.left = `${productPosition.left}px`;
    cloneProduct.style.transition = "transform 2s ease, opacity 1s ease";
    cloneProduct.style.transform = `translate3d(0px, 0px, 0px) scale(1)`;
    cloneProduct.style.height = `auto`;
    cloneProduct.style.zIndex = `1000001`;
    document.body.insertAdjacentElement("beforeend", cloneProduct);
    const basketPosition = getPosition(document.querySelector("#basket"));
    const cloneProductPosition = getPosition(cloneProduct);
    cloneProduct.style.transformOrigin = "top right";
    cloneProduct.style.transform = `translate3d(${
      basketPosition.x -
      cloneProductPosition.x -
      cloneProductPosition.width / 1.2
    }px, ${basketPosition.y - cloneProductPosition.y}px, 0px) scale(0)`;
    cloneProduct.style.opacity = `0`;
    setTimeout(() => {
      cloneProduct.remove();
    }, 1000 + Math.abs(basketPosition.x - cloneProductPosition.x));
  }

  const getPosition = (element) => {
    return element.getClientRects()[0];
  };

  return (
    <>
      <div className="basket basket-window search-results">
        <div className="basket__header">
          <div className="basket__title">Результаты поиска</div>
        </div>
        <div className="basket__items">
          {results?.map((product, index) => {
            return (
              <div key={index} className="basket__item basket-item">
                <div className="basket-item__image">
                  <img
                    loading="lazy"
                    src={product.main_image}
                    alt={product.name}
                  />
                </div>
                <div className="basket-item__text">
                  <div className="basket-item__title">
                    <a href={"/product/" + product.link}>{product.name}</a>
                  </div>
                  {product?.in_stock && (
                    <button
                      onClick={(event) => {
                        addClickHandler(event, product);
                      }}
                      className="product__link"
                    >
                      {isAlreadyInBasket(product.id)
                        ? "В корзине"
                        : "Добавить в корзину"}
                    </button>
                  )}
                </div>
              </div>
            );
          })}
          {!results.length && (
            <p className="basket__empty">По вашему запросу ничего не нашлось</p>
          )}
        </div>
      </div>
    </>
  );
}
