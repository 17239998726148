import * as React from "react";
import { useEffect, useRef, useState } from "react";
import Select from "./Select";

export default function InputSelect({
  name,
  title,
  items,
  label,
  htmlFor,
  changeHandler,
  defaultValue,
  inputBoxClass,
  labelClass,
  inputClass,
}) {
  const [values, setValues] = useState([]);
  useEffect(() => {
    setValues(items);
  }, [items]);

  return (
    <Select
      name={name}
      selectClass={"input__select"}
      callback={changeHandler}
      search={true}
      title={title}
      items={values}
      label={label}
      defaultValue={defaultValue}
      htmlFor={htmlFor}
      inputBoxClass={inputBoxClass}
      labelClass={labelClass}
      inputClass={inputClass}
    />
  );
}
