import React, { useState, useEffect, useRef } from "react";
import Header from "../Header";
import Footer from "../Footer";
import { Sugar } from "../Sugar";
import Product1 from "../../img/Home/FirstScreen/01.png";
import Rating from "react-rating";
import Product2 from "../../img/Home/FirstScreen/02.png";
import Product3 from "../../img/Home/FirstScreen/03.png";
import Product4 from "../../img/Home/FirstScreen/04.png";
import { Autoplay, Navigation, Swiper, Grid } from "swiper";
import useHttp from "../../hooks/hooks.http";
import ProductCard from "../common/ProductCard";
export default function BrandProducts({ brand }) {
  const slider = useRef();
  useEffect(() => {
    if (slider.current.className)
      new Swiper("." + slider.current.classList[0], {
        modules: [Navigation, Autoplay, Grid],
        observer: true,
        observeParents: true,
        slidesPerView: 4,
        spaceBetween: 30,
        autoHeight: false,
        speed: 800,
        // Эффекты
        effect: "fade",
        // autoplay: {
        //     delay: 3000,
        //     disableOnInteraction: false,
        // },
        navigation: {
          nextEl: "." + slider.current.classList[0] + " .swiper-next-btn",
          prevEl: "." + slider.current.classList[0] + " .swiper-prev-btn",
        },
        breakpoints: {
          320: {
            slidesPerView: 2,
            slidesPerColumn: 2,
            spaceBetween: 10,
            grid: {
              fill: "row",
              rows: 2,
            },
          },
          960: {
            slidesPerView: 2,
            spaceBetween: 10,
          },
          1440: {
            slidesPerView: 4,
            spaceBetween: 20,
          },
          1920: {
            slidesPerView: 4,
          },
        },
        // События
        on: {},
      });
  }, [slider]);
  const { request, loading, error, isOk } = useHttp();
  const [brandProducts, setBrandProducts] = useState([]);
  useEffect(() => {
    console.log(brand);
    if (brand?.products) setBrandProducts([...brand?.products]);
  }, [brand]);

  return (
    <>
      <section className="page__popular popular">
        <div className="popular__header">
          <h2 className="popular__title section-title">Товары {brand?.name}</h2>
        </div>
        <div className="popular__container">
          <div
            ref={slider}
            className="popular__content products sponsors-swiper"
          >
            <div className="popular-products__swipe-anim">
              <img src={require("../../img/swipe.gif")} alt="" />
            </div>
            <div className="first-screen__swiper-wrapper swiper-wrapper ">
              {brandProducts?.map((brandProduct) => {
                return <ProductCard product={brandProduct} />;
              })}
            </div>
            <div className="brands__buttons">
              <button className="swiper-prev-btn brands-prev-btn _icon-arrow-slider"></button>
              <button className="swiper-next-btn brands-next-btn _icon-arrow-slider"></button>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
