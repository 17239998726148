import React, { useState, useEffect, useContext } from "react";
import Header from "../Header";
import Footer from "../Footer";
import FirstScreen from "./FirstScreen";
import Advantages from "./Advantages";
import PopularProducts from "./PopularProducts";
import PopularCategories from "./PopularCategories";
import Brands from "./Brands";
import About from "./About";
import { useNavigate, useParams } from "react-router-dom";
import useHttp from "../../hooks/hooks.http";
import Popup from "../common/Popup";
import SuccessPopup from "../SuccessPopup";
import ErrorPopup from "../ErrorPopup";
import { HeaderContext } from "../../context/HeaderContext";
import NewProducts from "./NewProducts";

export default function Home() {
  const history = useNavigate();
  const { request } = useHttp();
  const [products, setProducts] = useState(null);
  const [newProducts, setNewProducts] = useState(null);
  const [categories, setCategories] = useState(null);
  const [slides, setSlides] = useState(null);
  const [successPopup, setSuccessPopup] = useState(false);
  const [errorPopup, setErrorPopup] = useState(false);
  const { clearBasket } = useContext(HeaderContext);

  useEffect(() => {
    const req = async () => {
      try {
        const data = await request(`/api/products/popular/`);
        if (data) setProducts(data);
        const newProds = await request(`/api/products/?is_new=true`);
        if (newProds) setNewProducts(newProds);
        const cats = await request("/api/categories/");
        if (cats) setCategories(cats);
        const slides = await request("/api/content/find/main-slider/");
        if (slides) setSlides(slides);
      } catch (e) {}
    };
    req();
    document.title = "MamaOrganic - Главная";
    if (document.location.hash && document.location.hash === "#success_popup") {
      setSuccessPopup(true);
      clearBasket();
    } else if (
      document.location.hash &&
      document.location.hash === "#error_popup"
    ) {
      setErrorPopup(true);
    }
  }, []);
  useEffect(() => {
    if (window.location.hash) {
      const goto = document.querySelector(window.location.hash);
      if (goto) {
        goto.scrollIntoView({
          behavior: "smooth",
          block: "start",
        });
      }
    }
  }, [history]);
  return (
    <>
      <Header />
      <main>
        <FirstScreen products={slides?.file_content} />
        {!!newProducts?.results.length && (
          <NewProducts products={newProducts?.results} />
        )}
        <PopularProducts products={products?.results} />
        <PopularCategories categories={categories} />
        <Brands />
        <About />
        <Advantages />
        <Popup active={successPopup} setActive={setSuccessPopup}>
          <SuccessPopup />
        </Popup>
        <Popup active={errorPopup} setActive={setErrorPopup}>
          <ErrorPopup />
        </Popup>
      </main>
      <Footer />
    </>
  );
}
