import React, { useState, useEffect, useContext, useCallback } from "react";
import Header from "../Header";
import Footer from "../Footer";
import CheckoutForm from "./CheckoutForm";
import { HeaderContext } from "../../context/HeaderContext";
import CheckoutProducts from "./CheckoutProducts";
import useHttp from "../../hooks/hooks.http";
let timeOut = null;
let timeOut1 = null;

export default function Checkout() {
  const [products, setProducts] = useState([]);
  const [discountText, setDiscountText] = useState("");
  const { request } = useHttp();
  const [promocodeData, setPromocodeData] = useState({});
  const [promocode, setPromocode] = useState(null);
  const [city, setCity] = useState("");
  const {
    get,
    storageLength,
    addOne,
    removeById,
    removeOne,
    calculatePrice,
    calculatedPrice,
  } = useContext(HeaderContext);

  const getDiscountText = useCallback(async () => {
    try {
      const data = await request("/api/content/find/discount-text/");
      setDiscountText(data?.text);
    } catch (e) {}
  }, []);

  useEffect(() => {
    document.title = "Check-Out";
  }, []);

  useEffect(() => {
    calculatePrice(promocode, city);
  }, [promocode, city]);

  useEffect(() => {
    const prods = get();
    setProducts(prods);
  }, [storageLength]);

  useEffect(() => {
    getDiscountText();
  }, []);

  function promoChangeHandler(event) {
    if (timeOut !== null) {
      clearTimeout(timeOut);
    }
    if (event.target.value !== "")
      timeOut = setTimeout(async () => {
        try {
          const data = await request(`/api/promocodes/${event.target.value}`);
          event.target.classList.remove("error");
          setPromocodeData(data);
          setPromocode(event.target.value);
        } catch (e) {
          event.target.classList.add("error");
          setPromocodeData({});
          setPromocode(null);
        }
      }, 800);
    else {
      event.target.classList.remove("error");
      setPromocodeData({});
    }
  }
  function cityChangeHandler(event) {
    if (timeOut1 !== null) {
      clearTimeout(timeOut1);
    }
    if (event.target.value !== "")
      timeOut1 = setTimeout(async () => {
        setCity(event.target.value);
      }, 800);
  }

  return (
    <>
      <Header />
      <main className="checkout-main">
        <div className="checkout__hf-container">
          <CheckoutForm
            priceData={calculatedPrice || {}}
            products={products}
            discountText={discountText}
            calculatePrice={calculatePrice}
            promoChangeHandler={promoChangeHandler}
            cityChangeHandler={cityChangeHandler}
          />
          <CheckoutProducts
            products={products}
            addOne={addOne}
            removeById={removeById}
            removeOne={removeOne}
          />
        </div>
      </main>
      <Footer />
    </>
  );
}
