import { useEffect, useState } from "react";
import useHttp from "./hooks.http";

export const useStorage = (storageName) => {
  const [storageLength, setStorageLength] = useState(0);
  const [calculatedPrice, setCalculatedPrice] = useState({});
  const { request } = useHttp();
  const add = (data = []) => {
    let toStorage = [];
    let findId = 0;
    if (localStorage.getItem(storageName) !== null) {
      toStorage = JSON.parse(localStorage.getItem(storageName));
    }
    if (!toStorage.find((item) => item.id === data["id"])) {
      toStorage.push({ ...data, count: 1 });
      findId = toStorage.length - 1;
    } else {
      findId = toStorage.indexOf(
        toStorage.find((item) => item.id === data["id"])
      );
      toStorage[findId] = {
        ...toStorage[findId],
        count: parseInt(toStorage[findId].count) + 1,
      };
    }
    if (toStorage[findId].count <= data.amount) {
      localStorage.removeItem(storageName);
      localStorage.setItem(storageName, JSON.stringify(toStorage));
      let storageLength = 0;
      for (const toStorageElement of toStorage) {
        storageLength += toStorageElement.count;
      }
      setStorageLength(storageLength);
      return true;
    }
    return false;
  };
  const get = () => {
    return JSON.parse(localStorage.getItem(storageName)) ?? [];
  };
  const removeById = (id) => {
    const data = JSON.parse(localStorage.getItem(storageName)) ?? [];
    localStorage.removeItem(storageName);
    const newData = data.filter((dataObj, index) => index != id);
    console.log(data);
    localStorage.setItem(storageName, JSON.stringify(newData));
    setStorageLength(JSON.parse(localStorage.getItem(storageName)).length);
  };
  const addOne = (id) => {
    const data = JSON.parse(localStorage.getItem(storageName)) ?? [];
    if (data[id].count + 1 <= data[id].amount) {
      localStorage.removeItem(storageName);
      data[id].count += 1;
      localStorage.setItem(storageName, JSON.stringify(data));
      setStorageLength(JSON.parse(localStorage.getItem(storageName)).length);
      return true;
    }
    return false;
  };
  const removeOne = (id) => {
    const data = JSON.parse(localStorage.getItem(storageName)) ?? [];
    if (data[id].count - 1 === 0) {
      removeById(id);
      return;
    }
    localStorage.removeItem(storageName);
    data[id].count -= 1;
    localStorage.setItem(storageName, JSON.stringify(data));
    setStorageLength(JSON.parse(localStorage.getItem(storageName)).length);
  };
  const updateOne = (dataOne) => {
    const data = JSON.parse(localStorage.getItem(storageName)) ?? [];
    if (!!data.find((item) => item.id === dataOne.id)) {
      const new_data = data.map((item) => {
        if (item.id === dataOne.id) return dataOne;
        return item;
      });
      localStorage.removeItem(storageName);
      localStorage.setItem(storageName, JSON.stringify(new_data));
    }
  };
  const removeErrors = () => {
    const data = JSON.parse(localStorage.getItem(storageName)) ?? [];
    const new_data = data.map((item) => {
      return { ...item, error: null };
    });
    localStorage.removeItem(storageName);
    localStorage.setItem(storageName, JSON.stringify(new_data));
  };
  const clearBasket = () => {
    localStorage.removeItem(storageName);
    setStorageLength(0);
    setCalculatedPrice({});
  };
  useEffect(() => {
    if (localStorage.getItem(storageName) !== null) {
      const data = JSON.parse(localStorage.getItem(storageName));
      let storageLength = 0;
      for (const toStorageElement of data) {
        storageLength += toStorageElement.count;
      }
      setStorageLength(storageLength);
      (async () => {
        await calculatePrice();
      })();
    }
  }, [storageLength]);
  const calculatePrice = async (promo, city) => {
    try {
      removeErrors();
      const productsToCalculate = [];
      for (const productsToCalculateElement of get()) {
        if (
          !productsToCalculateElement.error ||
          (productsToCalculateElement.error &&
            !Object.keys(productsToCalculateElement.error).length)
        )
          productsToCalculate.push({
            amount: productsToCalculateElement.count,
            product: productsToCalculateElement.id,
          });
      }
      const body = {
        products: productsToCalculate,
      };
      if (promo) body["promocode"] = promo;
      if (city) body["city"] = city;
      const data = await request("/api/orders/calculate-price/", "POST", body, {
        "Content-Type": "application/json",
      });
      if (data) setCalculatedPrice(data);
    } catch (e) {
      const productsErrors = JSON.parse(e.message);
      const basketProducts = get();
      if (productsErrors?.products) {
        productsErrors.products.map((productError, index) => {
          if ("product" in productError) {
            removeOne(index);
          }
          if (productError.product_id && productError.product_id[0]) {
            if (productError.error[0] === "Товара нет в наличии")
              removeById(index);
            else
              updateOne({
                ...basketProducts.filter(
                  (basketProduct) =>
                    basketProduct.id === parseInt(productError.product_id[0])
                )[0],
                error: productError.error[0],
              });
          }
        });
      }
    }
  };
  const isAlreadyInBasket = (id) => {
    const products = get();
    return products.find((product) => product.id === id);
  };

  return {
    add,
    get,
    removeById,
    storageLength,
    addOne,
    removeOne,
    clearBasket,
    updateOne,
    removeErrors,
    calculatePrice,
    calculatedPrice,
    isAlreadyInBasket,
  };
};
