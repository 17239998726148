import React from "react";
import ErrorImage from "../img/error.png";

function ErrorPopup(props) {
  return (
    <div className={"modal-purchase"}>
      <div className="modal-purchase__header">
        <div className="modal-purchase__icon">
          <img src={ErrorImage} alt="" />
        </div>
      </div>
      <div className="modal-purchase__text">
        <h2 className="modal-purchase__title">Что-то пошло не так</h2>
        <div className="modal-purchase__subtitle">
          <p>
            Попробуйте снова оплатить заказ, либо свяжитесь с нами
            contact@mamaorganic.kz.
          </p>
          <p>
            Так же Вы можете написать нам в Инстаграм. Мы всегда с Вами на
            связи.
          </p>
        </div>
      </div>
    </div>
  );
}

export default ErrorPopup;
