import React from "react";
import Header from "./Header";
import Img404 from "../img/404.png";
import Footer from "./Footer";

function FourOhFour(props) {
  return (
    <>
      <Header />
      <section className="page__404 four-oh-four">
        <div className="four-oh-four__image">
          <img src={Img404} alt="" />
        </div>
        <h1 className="four-oh-four__title">Упс. Мы не нашли такую страницу</h1>
        <a href="/" className={"four-oh-four__button"}>
          Вернуться назад
        </a>
      </section>
      <Footer />
    </>
  );
}

export default FourOhFour;
